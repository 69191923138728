import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header className="px-4 pt-4 max-w-2xl mx-auto sm:px-6 lg:max-w-4xl lg:px-8 xl:max-w-6xl">
    <Link className="flex items-center underline text-lg tracking-tight" to="/">
      <span className="pr-1">
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
          />
        </svg>
      </span>
      TDD Phoenix
    </Link>
  </header>
)

export default Header
