import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"

const WorkInProgressMessagePage = () => (
  <>
    <Header />
    <Layout>
      <SEO title="TDD Phoenix: Work In Progress Message" />
      <div className="prose lg:prose-lg max-w-full">
        <h1>A Work in Progress</h1>
        <div>
          <p>
            Test-driven development (TDD) is a practice that changed my career.
            Many years ago, I was fortunate enough to learn it via an in-person
            workshop <Link to="https://thoughtbot.com/">thoughtbot</Link> was
            offering. After almost a decade practicing it with Rails and
            Phoenix, it has become completely ingrained in how I work.
          </p>
          <p>
            But I was <span className="italic">fortunate</span>. Not everyone
            has the chance to attend workshops like that. So I wanted to pay it
            forward in some way. I wanted to help others learn this wonderful
            craft. And writing a{" "}
            <span className="italic">Test-Driven Development with Phoenix</span>{" "}
            book that is freely accessible seemed like a good first step.
          </p>
          <p>
            Even though it's not finished, I made it available as a
            work-in-progress for three reasons:
          </p>
          <ol>
            <li>
              Though the writing needs editing, the overall TDD process is
              clear, and the application that we build throughout the book is
              fully functional. So people can already benefit from it.
            </li>
            <li>
              I would love to get feedback from people who read it &mdash; to
              improve it <span class="italic">and</span> keep me motivated.
            </li>
            <li>
              Because it is always scary to write something and share it with
              the world. So I want to put it "out there" to be rid of that fear,
              lest the fear wins, and I fail to share it.
            </li>
          </ol>
          <p>
            Since my initial announcement, I've made several updates. I wrote
            about them in the following blog posts:
          </p>
          <ul>
            <li>
              <Link to="https://www.germanvelasco.com/blog/tdd-phoenix-a-work-in-progress">
                TDD Phoenix Update (Aug 2020)
              </Link>
            </li>
            <li>
              <Link to="https://www.germanvelasco.com/blog/tdd-phoenix-update-nov-2020">
                TDD Phoenix Update (Nov 2020)
              </Link>
            </li>
          </ul>
          <p>
            I hope you find the book interesting. I hope you find it helpful. If
            you have comments or suggestions, please let me know at{" "}
            <Link to="https://twitter.com/germsvel">@germsvel</Link> or{" "}
            <Link to="mailto:german@germanvelasco.com">send me an email</Link>.
            If the feedback is positive, tweet it out! If it's negative but
            constructive, send me a direct message or an email. And if it's only
            negative, well, just keep that to yourself. :)
          </p>
          <p>Thank you, and I hope you enjoy it!</p>
          <p className="italic">- German Velasco</p>

          <div className="text-right">
            <Link to="/introduction" className="flex justify-end items-center">
              <div className="font-bold">Get Started</div>
              <svg
                className="h-5 w-5 pl-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  </>
)

export default WorkInProgressMessagePage
